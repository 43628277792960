<template>
  <v-skeleton-loader
    type="image"
    v-if="!value"
  />
  <v-card v-else>
    <v-card-title>{{$t('t.Identification')}}</v-card-title>
    <v-card-text class="identification">
      <div class="name d-flex flex-row">
        <!-- dataCanChangeParent: {{dataCanChangeParent}}
        dataIsHeadOffice: {{dataIsHeadOffice}} -->
        <picker
          document-types="business-divisions"
          :clearable="true"
          :label="$t('t.ParentBusinessDivision')"
          :selected-id.sync="computedParentId"
          class="mr-4 parent"
          :dense="false"
          :disabled="parentDisabled"
          :rules="!parentDisabled && !userIsMasterAdmin ? parentDivisionRules : []"
        />
        <v-text-field
          :label="$t('t.Name')"
          v-model="computedName"
          :rules="nameRules"
          :clearable="clearable"
        />
        <v-switch
          class="society mr-4"
          dense
          :label="$t('t.Society')"
          v-model="computedIsCompany"
        />

      </div>
      <div class="import d-flex flex-row">
        <v-text-field
          class="import-key"
          :label="$t('t.ImportKey')"
          v-model="computedImportKey"
          :rules="importKeyRules"
          :clearable="clearable"
        />

        <v-switch
          dense
          :disabled="defaultForImport"
          :label="$t('t.IsDefaultForImport')"
          v-model="computedIsDefaultForImport"
        />
      </div>
      <div class="d-flex flex-row">
        <v-text-field
          v-if="computedGroupamaIsActive"
          class="external-number"
          :label="$t('t.GroupamaCodeAssure')"
          v-model="computedGroupamaCodeAssure"
          :rules="ExternalNumberRules"
          :clearable="clearable"
        />
        <v-text-field
          v-if="computedIsCompany"
          class="external-number"
          :label="$t('t.BusinessIdentifierCompany')"
          v-model="computedBusinessIdentifier"
          :rules="ExternalNumberRules"
          :clearable="clearable"
        />
        <v-text-field
          class="phone"
          :label="$t('t.Phone')"
          v-model="computedPhone"
          :rules="phoneRules"
          :clearable="clearable"
        >
          <template v-slot:append>
            <phone-btn
              :phone-number="computedPhone"
              :show-phone-number="false"
              normal
            />
          </template>
        </v-text-field>
        <v-text-field
          class="phone"
          :label="$t('t.Fax')"
          v-model="computedFax"
          :rules="phoneRules"
          :clearable="clearable"
        />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  components: {
    Picker: () => import('@/components/picker'),
    PhoneBtn: () => import('@/components/phone-btn')
  },
  data () {
    return {
      parentDivisionRules: [v => (!!v) || this.$t('t.IsRequired')],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataId: null,
      dataBusinessIdentifier: null,
      dataName: null,
      dataEmail: null,
      dataGroupamaCodeAssure: null,
      dataFax: null,
      dataImportKey: null,
      dataIsDefaultForImport: false,
      dataIsHeadOffice: false,
      dataPhone: null,
      dataParentId: null,
      dataCompanyId: null,
      dataIsCompany: false
    }
  },
  computed: {
    parentDisabled () {
      return this.dataIsHeadOffice && !this.userIsMasterAdmin
    },
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    importKeyRules () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 20
      ]
    },
    phoneRules () {
      return [
        v => (v ?? '').length <= 25
      ]
    },
    ExternalNumberRules () {
      return [
        v => (v ?? '').length <= 50
      ]
    },
    nameRules () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 100
      ]
    },
    emailRules () {
      const rules = [
        v => (v ?? '').length <= 500
      ]

      return rules
    },
    computedBusinessIdentifier: {
      get () {
        return this.dataBusinessIdentifier
      },
      set (v) {
        this.dataBusinessIdentifier = v
        this.dataDocumentEmit()
      }
    },
    computedName: {
      get () {
        return this.dataName
      },
      set (v) {
        this.dataName = v
        this.dataDocumentEmit()
      }
    },
    computedGroupamaCodeAssure: {
      get () {
        return this.dataGroupamaCodeAssure
      },
      set (v) {
        this.dataGroupamaCodeAssure = v
        this.dataDocumentEmit()
      }
    },
    computedGroupamaIsActive () {
      return this.value?.groupamaIsActive
    },
    computedFax: {
      get () {
        return this.dataFax
      },
      set (v) {
        this.dataFax = v
        this.dataDocumentEmit()
      }
    },
    computedImportKey: {
      get () {
        return this.dataImportKey
      },
      set (v) {
        this.dataImportKey = v
        this.dataDocumentEmit()
      }
    },
    computedIsDefaultForImport: {
      get () {
        return this.dataIsDefaultForImport
      },
      set (v) {
        this.dataIsDefaultForImport = v
        this.dataDocumentEmit()
      }
    },
    computedIsCompany: {
      get () {
        return this.dataIsCompany
      },
      set (v) {
        this.dataIsCompany = v
        this.dataDocumentEmit()
      }
    },
    computedPhone: {
      get () {
        return this.dataPhone
      },
      set (v) {
        this.dataPhone = v
        this.dataDocumentEmit()
      }
    },
    computedParentId: {
      get () {
        return this.dataParentId
      },
      set (v) {
        this.dataParentId = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        name: this.dataName,
        businessIdentifier: this.dataBusinessIdentifier,
        groupamaCodeAssure: this.dataGroupamaCodeAssure,
        fax: this.dataFax,
        importKey: this.dataImportKey,
        isDefaultForImport: this.dataIsDefaultForImport,
        phone: this.dataPhone,
        parentId: this.dataParentId,
        isCompany: this.dataIsCompany
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    value: Object,
    defaultForImport: Boolean
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataBusinessIdentifier = v?.businessIdentifier
        this.dataCanChangeParent = v?.canChangeParent ?? false
        this.dataId = v?.id
        this.dataName = v?.name
        this.dataGroupamaCodeAssure = v?.groupamaCodeAssure
        this.dataFax = v?.fax
        this.dataImportKey = v?.importKey
        this.dataIsDefaultForImport = v?.isDefaultForImport
        this.dataIsHeadOffice = v?.isHeadOffice
        this.dataPhone = v?.phone
        this.dataParentId = v?.parentId
        this.dataCompanyId = v?.companyId
        this.dataIsCompany = v?.isCompany
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-text-field
  margin-right 1em

.external-number, .import-key, .phone
  max-width 200px

.email
  max-width 400px
</style>
